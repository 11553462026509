<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="settings">
            <div class="settings-item">
              <router-link to="account" href="#" class="settings-link">
                <img src="/static/img/profile.svg" class="settings_img" />
                <span>{{ $t('general.account') }}</span>
              </router-link>
            </div>
            <div class="settings-item" v-if="isShowLocations && areLocationsVisible">
              <router-link to="location" class="settings-link">
                <img src="/static/img/user_map.svg" class="settings_img" />
                <span>{{ $t('general.location') }}</span>
              </router-link>
            </div>
            <div class="settings-item" v-if="isShowUsers">
              <router-link to="users" class="settings-link">
                <img src="/static/img/users.svg" class="settings_img" />
                <span>{{ $t('users.users') }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Settings',
  created() {
    const dropdown = document.querySelector('.nav-item.nav-user.open.dropdown');
    if (dropdown) {
      dropdown.classList.remove('open');
    }
  },
  computed: {
    isShowLocations() {
      if (this.isUserAdverisingAgent) {
        return false;
      }
      return true;
    },
    isShowUsers() {
      if (this.isUserAdverisingAgent) {
        return false;
      }
      return true;
    },
    isUserAdverisingAgent() {
      return this.currentUserData.role === 'advertising';
    },
    currentUserData() {
      return this.$store.state.userData;
    }
  }
};
</script>

<style lang="scss">
.settings {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.settings-item {
}

.settings-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #bcc5d0;
}
.settings-link.active {
  color: #167495;
}
.settings_img {
  width: 40px;
  opacity: 0.5;
}
.settings-link.active .settings_img {
  opacity: 1;
}
</style>
